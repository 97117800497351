.modal-banner-tag {
    max-height: 550px;
    overflow: auto;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    transition: width 0.2s ease, height 0.2s ease;
}

/* Exibir a barra de rolagem ao passar o mouse */
.scrollable-content:hover {
    width: 4px;
    height: 4px;
}

/* Estilo do trilho da barra de rolagem */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Estilo do polegar da barra de rolagem */
::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 10px;
}

/* Estilo do polegar ao passar o mouse */
.scrollable-content:hover {
    background: f1f1f1;
}