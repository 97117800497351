.mobile-size {
    width: 390px;
}


.image-date-view {
    position: absolute;
    background: #2d2d2d;
    border-radius: 6px;
    color: #f2a73b;
    padding: 4px 12px;
    margin-left: 16px;
    margin-top: 16px;
}