.ant-table-cell {
  padding: 8px !important;
}

body {
  font-family: 'Montserrat', sans-serif;
}

/* Outros elementos podem ser ajustados conforme necessário */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}

.ant-pagination {
  margin-bottom: 0px !important;
}

.ant-table-cell {
  border-start-end-radius: 0px !important;
  border-start-start-radius: 0px !important;
}

.search-container .ant-row {
  margin-bottom: 16px;
}

.ant-tabs-nav {
  margin-bottom: 0px !important;
}

.ant-splitter {
  box-shadow: none !important;
  border: 1px solid #e0e0e0 !important;
}

.ant-tabs-card {
  border: none !important;
}

.ant-tree-treenode-selected::before {
  background: #fff !important;

}

.ant-tree-node-selected {
  color: #000000E0 !important;
}

.max-width-view {
  max-width: 1296px;
  margin: 0px auto;
}