.perfil-container {
    max-height: 430px;
    overflow: auto;
}

.perfil-container::-webkit-scrollbar {
    width: 5px;
}

.perfil-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.perfil-container::-webkit-scrollbar-thumb {
    background: #f2a73b;
    border-radius: 10px;
}

.perfil-container::-webkit-scrollbar-thumb:hover {
    background: #f2a73b;
}