@import '../../../../../styles/variables';

/* Estilo para o container do scroll */
.product-most-purchase-container {
    background: #fcfcfc;
}

.dashboard-product {
    max-height: 185px;
    overflow: auto;
}

.dashboard-product::-webkit-scrollbar {
    width: 5px;
}

.dashboard-product::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.dashboard-product::-webkit-scrollbar-thumb {
    background: #f2a73b;
    border-radius: 10px;
}

.dashboard-product::-webkit-scrollbar-thumb:hover {
    background: #f2a73b;
}