.box-branch {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.branch-card {
    display: flex !important;
    align-items: center;
    text-align: center;
    font-size: 13px;
    grid-gap: 16px;
    height: 150px;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;

    img {
        max-width: 90px;
        max-height: 90px;
    }

    &:nth-child(6) {
        border-right: none;
    }

    &:nth-child(12) {
        border-right: none;
    }

    &:nth-child(18) {
        border-right: none;
    }
}