.autocomplete-container {
    .ant-typography {
        font-size: 14px !important;
    }

    .ant-select-selector {
        border-radius: 4px !important;
    }

    .ant-spin {
        margin-left: -35px !important;
        position: absolute;
        margin-top: 10px;
    }

    .ant-select-selection-search-input {
        height: auto !important;
    }
}