.container-order {
  display: flex;
  width: 100%;
  margin-top: 24px;
  justify-content: center;

  .container- {
    text-align: center;
  }

  .canceled-text {
    p {
      color: #444 !important;
    }
  }

  .canceled {
    border: 1px solid #de220d !important;

    svg {
      color: #de220d;
    }
  }

  .progress-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  .progress-container:before {
    content: '';
    background-color: #dedede;
    position: absolute;
    top: 18px;
    left: 27px;
    transform: translateY(-50%);
    height: 2px;
    width: 92%;
  }

  .progress {
    background-color: #dedede;
    position: absolute;
    top: 18px;
    left: 20px;
    transform: translateY(-50%);
    height: 4px;
    max-width: 80%;
    width: 0%;
    z-index: -1;
    transition: 400ms ease;
  }

  .text-wrap {
    display: inline-block;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 88px;
  }

  .text-wrap p {
    font-weight: 400;
    font-size: 12px;
    color: #bababa;
    margin-bottom: 0px;
    margin-top: 8px;
    line-height: 14px;
    height: 30px;
  }

  .icon-color {
    color: #adadad;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-wrap.active p {
    font-weight: 500;
    color: black;
    transition: 400ms ease;
  }

  .circle {
    background-color: #fff;
    font-weight: 600;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    position: relative;
    /* Position the child element */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 400ms ease;
    border: 2px solid #e0e0e0;

    svg {
      font-size: 20px;
    }
  }

  .text-wrap.active div.circle {
    border-color: #0bb45a;
    color: #0bb45a;
  }

  .text-date {
    font-size: 11px !important;
    margin-top: 6px !important;
    color: #898989 !important;
  }
}