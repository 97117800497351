.skeleton-container {}

.skeleton-description {
  font-size: 16px;
  margin-top: 16px;
}

.skeleton-price {
  margin-top: 0px;
}

.skeleton-block {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
}

.skeleton-image {
  width: 100%;
  height: 100%;
  max-height: 288px;

  span {
    height: 100%;
    max-height: 330px;
    height: 280px;

    @media (max-width: 768px) {
      max-height: 265px;
      height: 265px;
    }
  }

  p {
    height: 100%;
    max-height: 204px;
  }

  @media (max-width: 768px) {
    max-height: 275px;
  }
}