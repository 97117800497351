@import '../../../../../styles/variables';

.icon {
    width: 48px;
    height: 48px;
    background: #fdedd8;

    svg {
        color: #f2a73b;
        font-size: 20px;
    }
}