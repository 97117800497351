.input-mask-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-typography {
        font-size: 14px !important;
    }

    .ant-input-affix-wrapper {
        border-radius: 4px !important;
    }

    .ant-input {
        border-radius: 4px !important;
    }

    .error-border {
        .ant-input {
            background: #ffffff;
            border-width: 1px;
            border-style: solid;
            border-color: #ff4d4f;
        }
    }
}