.input-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-typography {
        font-size: 14px !important;
    }

    .ant-input {
        border-radius: 4px !important;
    }
}