.create-button {
    border: 1px solid #4CAF50 !important;
    color: #4CAF50;

    &:hover {
        opacity: 0.8;
        color: #4CAF50 !important;
    }
}

.delete-button {
    border: 1px solid #ed5555 !important;
    color: #ed5555;

    &:hover {
        opacity: 0.8;
        color: #ed5555 !important;
    }
}

.export-button {
    border: 1px solid #0c67af !important;
    color: #0c67af;

    &:hover {
        opacity: 0.8;
        color: #0c67af !important;
    }
}


.primary-button {
    border: 1px solid #f2a73b !important;
    background: #f2a73b;
    color: #fff;

    &:hover {
        opacity: 0.8;
        background: #f2a73b !important;
        color: #fff !important;
    }
}

.outline-button {
    border: 1px solid #f2a73b !important;
    background: #fff;
    color: #f2a73b;

    &:hover {
        opacity: 0.8;
        background: #f2a73b !important;
        color: #fff !important;
    }
}

.disabled-button {
    border: none !important;
    background: #898989;
    color: #fff;

    &:hover {
        background: #898989 !important;
        color: #fff !important;
    }
}

.filter-button {
    border: 1px solid #8A8A8A !important;
    color: #8A8A8A;

    &:hover {
        opacity: 0.8;
        color: #8A8A8A !important;
    }
}