.upload-button {
    padding: 4px 15px;
    min-width: max-content;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    min-height: 40px;
    display: flex;
    align-items: center;
}

.upload-button:hover {}