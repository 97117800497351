.input-container {
    width: 100%;

    .ant-typography {
        font-size: 14px !important;
    }

    .ant-picker {
        border-radius: 4px !important;
    }

    .ant-input {
        border-radius: 4px !important;
    }
}

.currency-input-container {
    input {
        width: 100%;
        border-width: 1px;
        border-style: solid;
        border-color: #d9d9d9;
        border-radius: 4px;
        padding: 4px 6px;
        height: 32px;
    }
}