.view-home-button {
    border: 1px solid #e0e0e0;
    height: 40px;
    align-items: center;
    border-radius: 6px;
    padding: 0px 15px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.container-component {
    border: 1px solid #f7f7f7;
    transition: box-shadow 0.2s ease;

    &:hover {
        box-shadow: 0 0 2px 2px #f2a73b; // Use 2px para simular a borda mais grossa
    }
}

.home-options-container {
    width: 100%;
}

.options-home {
    background: #2d2d2d;
    border-radius: 6px;
    position: absolute;
    z-index: 99999;
    margin-top: 16px;
    right: 48px;
    color: #f2a73b;
    font-size: 16px;
}

.option-list {
    >div {
        padding: 8px 14px;
        border-radius: 6px;

        &:hover {
            background: #4d4d4d;
        }
    }
}

.home-icon {
    border-radius: 0px;
    color: #fff;
}


.options-home-date {
    background: #2d2d2d;
    border-radius: 6px;
    position: absolute;
    z-index: 99999;
    margin-top: 19px;
    left: 45px;
    color: #f2a73b;
    font-size: 16px;
}

.option-home-date-text {
    padding: 4px 12px;
}