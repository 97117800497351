.button-carrousel {
    border: 1px solid rgb(192, 33, 53);
    color: rgb(192, 33, 53);
    background: transparent !important;
    padding: 0px 24px;
    cursor: pointer;
    border-radius: 100px;
    min-height: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}


.carrosel-products {
    .slick-next {
        margin-right: -24px !important;
    }

    .slick-prev {
        margin-left: -24px !important;
    }
}

.container-skeleton {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 24px;
}

.container-skeleton-web {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
}