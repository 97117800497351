.rating {
    width: 18px;
    display: flex;
}

.ant-rate-star {
    margin-right: 4px !important;
}

.ant-rate-star-second {
    svg {
        width: 16px;
    }
}