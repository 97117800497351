.product-card {
    display: flex;
    flex-direction: column;
}

.product-image {
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-information {
    background: #f0f0f0;
    padding: 85px 24px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 24px;
    margin-top: -70px;
}

.product-font {
    font-size: 16px;
    font-weight: 600;
}

.product-description {
    min-height: 68px;
}


.product-fake-button {
    border-radius: 100px;
    background: #c02135;
    max-width: 200px;
    width: 100%;
    min-height: 38px;
    font-size: 13px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.product-fake-button-indisponivel {
    border-radius: 100px;
    background: #676767;
    max-width: 200px;
    width: 100%;
    min-height: 38px;
    font-size: 13px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.product-font-height {
    min-height: 25px;
}