@import './styles/variables';

.ant-table-cell:last-child {
    border-bottom: none;
}

// BUTTON 
.ant-btn-primary {
    background: $primary-color !important;
    color: #fff;

    &:hover {
        opacity: 0.8;
    }
}

.ant-btn {
    box-shadow: none !important;
    height: 40px;

    span {
        align-self: auto !important;
    }
}

.color-picker-disabled {
    background: #fff;

    span {
        color: #000000e0;
    }

    .ant-color-picker-trigger-text {
        width: max-content;
    }
}

.slick-next {
    color: #000 !important;
}

.slick-prev {
    color: #000 !important;
}

.copyright {
    bottom: 20px;
}

.rc-tree-title {
    font-size: 14px !important;
}

.ant-table-content {
    td {
        overflow-wrap: anywhere;
    }
}